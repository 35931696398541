import React from 'react'
import { ImageURISource, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AvatarCircle } from '../../common/avatar/avatar-circle'
import { SansText } from '../../common/copy/text-sans'
import { Row } from '../../common/row/row'
import { Column } from '../../common/column/column'
import { InfoText } from '../../common/copy/text-info'
import ConditionalLink from '../../../utilities/ConditionalLink'
import { RootStackParamList } from '../../../navigation/types'
import { useColorModeValue } from 'native-base'
import {
  MUTED_TEXT_COLOR_LIGHT,
  MUTED_TEXT_COLOR_DARK,
} from '../../../constants/ui-constants'
import { DeliveryMethod } from '../../../../../api/frontend-types'
import { ElsewhereIconType } from '../../../modules/ui-helpers/icon-map'
import { ElsewhereIcon } from '../../common/elsewhere-icon/elsewhere-icon'

type FeedEntryDreamServiceProps = {
  maxChars?: number
  imageSource?: ImageURISource
  title: string
  subTitle?: string
  deliveryMethods: DeliveryMethod[]
  linkProps: {
    screen: keyof RootStackParamList
    params?: any
  }
  location?: string
}

const FeedEntryDreamService = ({
  imageSource,
  title,
  subTitle,
  linkProps,
  location,
  deliveryMethods,
}: FeedEntryDreamServiceProps) => {
  const isWeb = Platform.OS === 'web'

  // HOOKS
  const { t } = useTranslation()
  const mutedColor = useColorModeValue(
    MUTED_TEXT_COLOR_LIGHT,
    MUTED_TEXT_COLOR_DARK,
  )

  return (
    <Row
      alignItems={'center'}
      justifyContent={'space-between'}
      minHeight={75}
      paddingLeft={3}
      paddingRight={3}
      paddingY={4}
    >
      <Row>
        <Row>
          <ConditionalLink condition={isWeb} to={linkProps}>
            <AvatarCircle size={'md'} source={imageSource} name={title} />
          </ConditionalLink>
          <Column marginX={3} paddingTop={1}>
            <ConditionalLink condition={isWeb} to={linkProps}>
              <SansText>{title}</SansText>
            </ConditionalLink>
            <ConditionalLink condition={isWeb} to={linkProps}>
              <InfoText>{subTitle}</InfoText>
            </ConditionalLink>
            <Row alignItems={'center'} mt={2} ml={-1}>
              <ElsewhereIcon
                borderRadius={'full'}
                iconKey={'map-pin'}
                color={mutedColor}
              />
              <InfoText marginX={2}>{location}</InfoText>
            </Row>
            <Row ml={-1} mt={2} alignItems={'center'}>
              {deliveryMethods.map((method, i) => {
                const iconKey = (method as ElsewhereIconType) || 'offline'
                console.log(`iconKey: ${iconKey}`)

                return (
                  <>
                    <Row
                      alignItems={'center'}
                      key={`${method}-${i}`}
                      marginLeft={i === 0 ? 0 : 2}
                    >
                      <ElsewhereIcon
                        borderRadius={'full'}
                        iconKey={iconKey}
                        color={mutedColor}
                      />
                      <InfoText marginX={2}>
                        {t(`dreamGroupsAndServices.${method}`)}
                      </InfoText>
                    </Row>
                    {i < deliveryMethods.length - 1 && (
                      <InfoText marginX={2}>/</InfoText>
                    )}
                  </>
                )
              })}
            </Row>
            {/* dreamGroupsAndServices.inPerson */}
          </Column>
        </Row>
      </Row>
      <ConditionalLink condition={isWeb} to={linkProps}>
        <ElsewhereIcon iconKey="caret-i18n" />
      </ConditionalLink>
    </Row>
  )
}

export default FeedEntryDreamService
