export const FULLY_SUPPORTED_LANGUAGES: string[] = [
  'en',
  'ja',
  'de',
  'fr',
  'id',
  'es',
  'pt',
  'da',
  'uk',
  'hu',
  'uk',
  'pl',
  'el',
  'it',
]

const LANGUAGES_IN_BETA: string[] = ['ru', 'fa']

export const SUPPORTED_LANGUAGES = [
  ...FULLY_SUPPORTED_LANGUAGES,
  ...LANGUAGES_IN_BETA,
]

export const FEW = 3
