//@ts-ignore
import { default as base } from 'native-base/lib/module/theme/base'
import { Platform } from 'react-native'
import {
  LG_FONTSIZE_PIXELS,
  LG_LINE_HEIGHT,
  MD_FONTSIZE_PIXELS,
  MD_LINE_HEIGHT,
  SM_FONTSIZE_PIXELS,
  SM_LINE_HEIGHT,
  XL_FONTSIZE_PIXELS,
  XL_LINE_HEIGHT,
  XS_FONTSIZE_PIXELS,
  XS_LINE_HEIGHT,
  XXL_FONTSIZE_PIXELS,
  XXL_LINE_HEIGHT,
  XXS_FONTSIZE_PIXELS,
  XXS_LINE_HEIGHT,
  XXXL_FONTSIZE_PIXELS,
  XXXL_LINE_HEIGHT,
  XXXXL_FONTSIZE_PIXELS,
  XXXXL_LINE_HEIGHT,
  XXXXXL_FONTSIZE_PIXELS,
  XXXXXL_LINE_HEIGHT,
} from '../constants/constants'
import {
  PLACEHOLDER_COLOR_DARK,
  PLACEHOLDER_COLOR_LIGHT,
  MUTED_TEXT_COLOR_LIGHT,
} from '../constants/ui-constants'

// Theoretically this should be somewhere in native base...? Maybe not...
export type Size = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

const units = {
  '2xs': 5,
  xs: 8,
  sm: 10,
  md: 12,
  lg: 20,
  xl: 32,
  '2xl': 48,
}

// These numbers are in 4 point increments
// E.g. 12 = 48px
// And all of them have to be in the 'space' object below
export const avatarAndIconButtonSizes: {
  [key in Size]: {
    width: number
    height: number
  }
} = {
  // 20 by 20, this size is used for tag icon buttons
  '2xs': {
    width: units['2xs'],
    height: units['2xs'],
  },
  xs: {
    width: units.xs,
    height: units.xs,
  },
  sm: {
    width: units.sm,
    height: units.sm,
  },
  md: {
    width: units.md,
    height: units.md,
  },
  lg: {
    width: units.lg,
    height: units.lg,
  },
  xl: {
    width: units.xl,
    height: units.xl,
  },
  '2xl': {
    width: units['2xl'],
    height: units['2xl'],
  },
}

export const buttonSizes = {
  '2xs': {
    height: units['2xs'],
  },
  xs: {
    height: units.xs,
  },
  sm: {
    height: units.sm,
  },
  md: {
    height: units.md,
  },
  lg: {
    height: units.lg,
  },
  xl: {
    height: units.xl,
  },
  '2xl': {
    height: units['2xl'],
  },
}

// We need these to relate to the avatarAndIconButtonSizes
// Since icons go inside IconButtons
export const iconSizes: {
  [key in Size]: number
} = {
  '2xs': 3,
  xs: 5,
  sm: 6,
  md: 8,
  lg: 12,
  xl: 18,
  '2xl': 32,
}

export const theme = {
  ...base,
  config: {
    useSystemColorMode: true,
  },
  fontConfig: {
    AlbertusMTStd: {
      300: {
        normal: 'AlbertusMTStd-Light',
      },
      400: {
        normal: 'AlbertusMTStd',
        italic: 'AlbertusMTStd-Italic',
      },
    },
    ABCFavorit: {
      300: {
        normal: 'ABCFavorit-Light',
        italic: 'ABCFavorit-LightItalic',
      },
      400: {
        normal: 'ABCFavorit-Book',
        italic: 'ABCFavorit-BookItalic',
      },
      500: {
        normal: 'ABCFavorit-Regular',
        italic: 'ABCFavorit-RegularItalic',
      },
      600: {
        normal: 'ABCFavorit-Medium',
        italic: 'ABCFavorit-MediumItalic',
      },
      700: {
        normal: 'ABCFavorit-Bold',
        italic: 'ABCFavorit-BoldItalic',
      },
      800: {
        normal: 'ABCFavorit-Bold',
        italic: 'ABCFavorit-BoldItalic',
      },
    },
    FiraCode: {
      300: {
        normal: 'FiraCode-Light',
      },
      400: {
        normal: 'FiraCode-Regular',
      },
      500: {
        normal: 'FiraCode-Medium',
      },
      600: {
        normal: 'FiraCode-SemiBold',
      },
      700: {
        normal: 'FiraCode-Bold',
      },
    },
    Optima: {
      400: {
        normal: 'OptimaLTPro-Roman',
        italic: 'OptimaLTPro-Italic',
      },
      500: {
        normal: 'OptimaLTPro-Medium',
        italic: 'OptimaLTPro-MediumItalic',
      },
      600: {
        normal: 'OptimaLTPro-DemiBold',
        italic: 'OptimaLTPro-DemiBoldItalic',
      },
      700: {
        normal: 'OptimaLTPro-Bold',
        italic: 'OptimaLTPro-BoldItalic',
      },
      800: {
        normal: 'OptimaLTPro-Black',
        italic: 'OptimaLTPro-BlackItalic',
      },
      900: {
        normal: 'OptimaLTPro-ExtraBlack',
        italic: 'OptimaLTPro-ExtraBlackItalic',
      },
    },
    Gulzar: {
      300: {
        normal: 'Gulzar-Regular',
        italic: 'Gulzar-Regular',
      },
    },
    NotoNaskh: {
      400: {
        normal: 'NotoNaskhArabic-Regular',
        italic: 'NotoNaskhArabic-Regular',
      },
      500: {
        normal: 'NotoNaskhArabic-Medium',
        italic: 'NotoNaskhArabic-Medium',
      },
      600: {
        normal: 'NotoNaskhArabic-SemiBold',
        italic: 'NotoNaskhArabic-SemiBold',
      },
      700: {
        normal: 'NotoNaskhArabic-Bold',
        italic: 'NotoNaskhArabic-Bold',
      },
    },
    NotoSans: {
      400: {
        normal: 'NotoSansArabic-Regular',
        italic: 'NotoSansArabic-Regular',
      },
      500: {
        normal: 'NotoSansArabic-Medium',
        italic: 'NotoSansArabic-Medium',
      },
      600: {
        normal: 'NotoSansArabic-SemiBold',
        italic: 'NotoSansArabic-SemiBold',
      },
    },
    Artifex: {
      300: {
        normal: 'ArtifexHandCF-ExtraLight',
        italic: 'ArtifexCF-ExtraLightItalic',
      },
      400: {
        normal: 'ArtifexHandCF-ExtraLight',
        italic: 'ArtifexCF-ExtraLightItalic',
      },
      500: {
        normal: 'ArtifexCF-DemiBold',
        italic: 'ArtifexCF-DemiBoldItalic',
      },
      600: {
        normal: 'ArtifexCF-DemiBold',
        italic: 'ArtifexCF-DemiBoldItalic',
      },
      700: {
        normal: 'ArtifexCF-DemiBold',
        italic: 'ArtifexCF-DemiBoldItalic',
      },
    },
    OpenSans: {
      400: {
        normal: 'OpenSans-Regular',
        italic: 'OpenSans-Italic',
      },
      500: {
        normal: 'OpenSans-SemiBold',
        italic: 'OpenSans-SemiBoldItalic',
      },
      600: {
        normal: 'OpenSans-SemiBold',
        italic: 'OpenSans-SemiBoldItalic',
      },
    },
  },
  fonts: {
    heading: 'AlbertusMTStd',
    headingArabic: 'Gulzar',
    headingCyrillic: 'Artifex',
    body: 'Optima',
    bodyArabic: 'NotoNaskh',
    bodyCyrillic: 'Artifex',
    sans: 'ABCFavorit',
    sansArabic: 'NotoSans',
    sansCyrillic: 'OpenSans',
    mono: 'FiraCode',
  },
  fontSizes: {
    '2xs': XXS_FONTSIZE_PIXELS,
    xs: XS_FONTSIZE_PIXELS,
    sm: SM_FONTSIZE_PIXELS,
    md: MD_FONTSIZE_PIXELS,
    lg: LG_FONTSIZE_PIXELS,
    xl: XL_FONTSIZE_PIXELS,
    '2xl': XXL_FONTSIZE_PIXELS,
    '3xl': XXXL_FONTSIZE_PIXELS,
    '4xl': XXXXL_FONTSIZE_PIXELS,
    '5xl': XXXXXL_FONTSIZE_PIXELS,
  },
  lineHeights: {
    '2xs': XXS_LINE_HEIGHT,
    xs: XS_LINE_HEIGHT,
    sm: SM_LINE_HEIGHT,
    md: MD_LINE_HEIGHT,
    lg: LG_LINE_HEIGHT,
    xl: XL_LINE_HEIGHT,
    '2xl': XXL_LINE_HEIGHT,
    '3xl': XXXL_LINE_HEIGHT,
    '4xl': XXXXL_LINE_HEIGHT,
    '5xl': XXXXXL_LINE_HEIGHT,
  },
  colors: {
    ...base.colors,
    text: {
      400: PLACEHOLDER_COLOR_LIGHT, // same as MUTED_TEXT_COLOR_DARK in NativeBase
      500: MUTED_TEXT_COLOR_LIGHT,
      600: PLACEHOLDER_COLOR_DARK,
    },
    // Change success color to "emerald.500"
    success: {
      50: '#10b981',
      100: '#10b981',
      200: '#10b981',
      300: '#10b981',
      400: '#10b981',
      500: '#10b981',
      600: '#10b981',
      700: '#10b981',
      800: '#10b981',
      900: '#10b981',
    },
    // Change info color to "blue.500"
    info: {
      50: '#3b82f6',
      100: '#3b82f6',
      200: '#3b82f6',
      300: '#3b82f6',
      400: '#3b82f6',
      500: '#3b82f6',
      600: '#3b82f6',
      700: '#3b82f6',
      800: '#3b82f6',
      900: '#3b82f6',
    },
    // Change warning to amber.500
    warning: {
      50: '#f59e0b',
      100: '#f59e0b',
      200: '#f59e0b',
      300: '#f59e0b',
      400: '#f59e0b',
      500: '#f59e0b',
      600: '#f59e0b',
      700: '#f59e0b',
      800: '#f59e0b',
      900: '#f59e0b',
    },
    // Change error to red.500
    error: {
      50: '#ef4444',
      100: '#ef4444',
      200: '#ef4444',
      300: '#ef4444',
      400: '#ef4444',
      500: '#ef4444',
      600: '#ef4444',
      700: '#ef4444',
      800: '#ef4444',
      900: '#ef4444',
    },
  },
  //
  space: {
    0: 0,
    0.5: 2,
    1: 4,
    1.5: 6,
    2: 8,
    2.5: 10,
    3: 12,
    3.5: 14,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    12: 48,
    16: 64,
    20: 80,
    24: 96,
    32: 128,
    36: 144,
    40: 160,
    48: 192,
    56: 224,
    64: 256,
    72: 288,
    80: 320,
    96: 384,
  },
  components: {
    // We need the Avatar and IconButton to be in sync
    // Because sometimes we use the IconButton as an Avatar
    // e.g. the 'add' button in the sidebar
    Avatar: {
      sizes: avatarAndIconButtonSizes,
      badgeSizes: {
        '2xs': {
          width: '8px',
          height: '8px',
        },
        '3xl': {
          width: '8px',
          height: '8px',
        },
      },
    },
    IconButton: {
      sizes: avatarAndIconButtonSizes,
    },
    Button: {
      sizes: buttonSizes,
    },
    Icon: {
      sizes: iconSizes,
    },
    Alert: {
      defaultProps: {
        borderWidth: 2,
        borderRadius: 0,
      },
    },
    // Make this so it can be full width, not max of 75%
    AlertDialog: {
      defaultProps: {
        size: 'full',
      },
    },
    Overlay: {
      defaultProps: {
        animationPreset: 'fade',
      },
    },
    Text: {
      defaultProps: {
        fontWeight: 300,
        selectionColor: Platform.OS === 'android' ? 'lightgray' : 'black',
        _dark: {
          selectionColor: Platform.OS === 'android' ? 'darkgray' : 'white',
        },
      },
      sizes: {
        '3xl': {
          fontSize: '256px',
        },
        '2xl': {
          fontSize: '128px',
        },
        xl: {
          fontSize: '64px',
        },
        lg: {
          fontSize: '32px',
        },
        md: {
          fontSize: '16px',
        },
        sm: {
          fontSize: '12px',
        },
      },
    },
    // https://github.com/GeekyAnts/NativeBase/issues/5420
    Input: {
      defaultProps: {
        _input: {
          selectionColor: Platform.OS === 'android' ? 'lightgray' : 'black',
          cursorColor: Platform.OS === 'android' ? 'black' : undefined,
        },
        _dark: {
          _input: {
            selectionColor: Platform.OS === 'android' ? 'lightgray' : 'white',
            cursorColor: Platform.OS === 'android' ? 'white' : undefined,
          },
          _focus: {
            _hover: {
              borderColor: 'currentColor',
            },
          },
          _hover: {
            borderColor: 'currentColor',
          },
        },
        _hover: {
          borderColor: 'currentColor',
        },
        _focus: {
          _hover: {
            borderColor: 'currentColor',
          },
          _stack: {
            style: {
              boxShadow: `0`,
            },
          },
        },
      },
    },
    TextArea: {
      defaultProps: {
        _input: {
          selectionColor: Platform.OS === 'android' ? 'lightgray' : 'black',
          cursorColor: Platform.OS === 'android' ? 'black' : undefined,
        },
        _dark: {
          _input: {
            selectionColor: Platform.OS === 'android' ? 'lightgray' : 'white',
            cursorColor: Platform.OS === 'android' ? 'white' : undefined,
          },
          _focus: {
            _hover: {
              borderColor: 'currentColor',
            },
          },
        },
        _focus: {
          _hover: {
            borderColor: 'currentColor',
          },
          _stack: {
            style: {
              boxShadow: `0`,
            },
          },
        },
      },
    },
  },
}
