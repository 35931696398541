import { AppThunk } from '../../store'
import { Platform } from 'react-native'
import { setNoCreditsMessageViewed } from '../../ui/ui'
import {
  setUser,
  setUserCreditsAvailable,
  setUserSubscriptionStatus,
} from '../../user/user'
import { NonSubscriptions } from '../../../revenuecat/types/general'
import {
  FastUser,
  FastUserService,
  SubscriptionStatusResponseService,
} from '../../../../../api/_openapi'
import {
  setSubscriptionStatus,
  setSubscriptionStatusLoading,
} from '../subscription'
import {
  SubscriptionStatus,
  SubscriptionTierKey,
} from '../../../../../api/frontend-types'
import {
  PurchasesEntitlementInfo,
  CustomerInfo,
  Subscriber,
} from '../../../revenuecat'
import { elsewhereToast } from '../../../components/common/toast/toast'
import { undecorateId } from '../../../../../api/decorate-ids'
import {
  bucketArtStylesByTier,
  bucketInterpretationStylesByTier,
} from '../../ui/helpers/ui-helpers'
import { getCustomerInfo } from '../../../hooks/useFeatureAccess/helpers'

export function refreshSubscriptionStatus(
  hardRefresh: boolean = false,
): AppThunk {
  return async (dispatch: any, getState) => {
    const userId = getState().user.user?.id || ''

    if (hardRefresh) {
      dispatch(setSubscriptionStatusLoading(true))
    }
    SubscriptionStatusResponseService.subscriptionStatus(hardRefresh)
      .then(async (response) => {
        // Update the subscription status
        dispatch(
          setSubscriptionStatusAndUpdateStyles(userId, {
            ...response,
            loading: false,
            tier: (response.tier || 'free') as SubscriptionTierKey,
          }),
        )
        // Update the credits available (on the user object)
        dispatch(setUserCreditsAvailable(response.creditsRemaining || 0))

        // Allow user to see 'no credits remaining' message again
        if ((response.imagesRemaining || 0) > 0) {
          dispatch(setNoCreditsMessageViewed(false))
        }
      })
      .catch((err) => {
        console.log('Error fetching subscription status', err)
      })
      .finally(() => {
        dispatch(setSubscriptionStatusLoading(false))
      })
  }
}

// Set the user's subscription status
// and update their art style and interpretation style if needed
export const setSubscriptionStatusAndUpdateStyles = (
  userId: string,
  newStatus: SubscriptionStatus,
): AppThunk => {
  // Get new tier and credits remaining
  const { tier: newTier, creditsRemaining: newCredits } = newStatus
  const newCreditsRemaining = newCredits || 0

  return async (dispatch: any, getState) => {
    const state = getState()
    // Get user
    const user: FastUser | undefined = getState().user.user
    if (!user) return

    // Get art & interp styles
    const artStyles = state?.ui.artStyles || []
    const interpStyles = state?.ui.interpretationStyles || []
    const { free: freeArtStyleIds, default: defaultArtStyleId } =
      bucketArtStylesByTier(artStyles)
    const { free: freeInterpStyleIds, default: defaultInterpStyleId } =
      bucketInterpretationStylesByTier(interpStyles)

    // Check if user has premium art style or interpretation style
    const currArtStyle = undecorateId(user?.artStyleId)
    const currInterpStyle = undecorateId(user?.interpretationStyleId)
    const hasFreeArtStyle = freeArtStyleIds.includes(currArtStyle)
    const hasFreeInterpStyle = freeInterpStyleIds.includes(currInterpStyle)
    const hasPremiumArtStyle = !hasFreeArtStyle
    const hasPremiumInterpStyle = !hasFreeInterpStyle

    // Check if user is out of credits and is now on free tier
    const outOfCredits = newCreditsRemaining <= 0
    const userIsFree = newTier === 'free'
    const noPremStylesAllowed = outOfCredits && userIsFree

    // Check if we should change the user's art style or interpretation style
    const shouldChangeArtStyle = hasPremiumArtStyle && noPremStylesAllowed
    const shouldChangeInterpStyle = hasPremiumInterpStyle && noPremStylesAllowed

    // Check if we should patch the user
    const shouldPatchUser = shouldChangeArtStyle || shouldChangeInterpStyle
    const userPatch = {
      ...(shouldChangeArtStyle && { artStyleId: defaultArtStyleId }),
      ...(shouldChangeInterpStyle && {
        interpretationStyleId: defaultInterpStyleId,
      }),
    }

    // Update subscription status
    dispatch(setSubscriptionStatus(newStatus))

    // Update user's art style and interpretation style as needed
    if (shouldPatchUser) {
      const updatedUser = await FastUserService.fastUserUpdate(
        userId,
        userPatch,
      ).catch((err) => {
        console.log('apiUsersIdPut error: ', err)
        elsewhereToast.showToast({
          description: 'common.errorGeneric',
          status: 'error',
        })
      })

      if (updatedUser) {
        const fastUser = await FastUserService.fastUser(userId)
        dispatch(setUser(fastUser))
        // Let user know that styles have been reverted
        elsewhereToast.showToast({
          title: 'toast.revertingStyles.title',
          description: 'toast.revertingStyles.description',
          status: 'info',
          duration: 5000,
        })
      } else {
        elsewhereToast.showToast({
          description: 'common.errorGeneric',
          status: 'error',
        })
      }
    }
  }
}

export function updateSubscriptionStatus(
  userId: string,
  subscriptionStatus: {
    subscriptionTier: SubscriptionTierKey
    creditsPurchased: number
    subscriptionInfo?: PurchasesEntitlementInfo
  },
): AppThunk {
  return async (dispatch: any) => {
    FastUserService.updateUserSubscription(userId, subscriptionStatus)
      .then((user) => {
        dispatch(setUserSubscriptionStatus(user))
      })
      .catch((err) => {
        console.log('Error fetching subscription status', err)
      })
  }
}

export function loadSubscriptionStatus(user: FastUser): AppThunk {
  return async (dispatch: any) => {
    try {
      if (user) {
        const customerInfo = await getCustomerInfo(user?.id)
        if (!customerInfo) {
          return
        }
        const newSubscriptionStatus =
          extractSubscriptionStatusFromCustomerInfo(customerInfo)
        dispatch(updateSubscriptionStatus(user?.id, newSubscriptionStatus))
        dispatch(refreshSubscriptionStatus())
      }
    } catch (e) {
      console.log('Error fetching subscription status', e)
    }
  }
}

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isArray = function (a: any) {
  return Array.isArray(a)
}

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const keysToCamel = function (o: any) {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach((k) => {
      // @ts-ignore
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i)
    })
  }

  return o
}

const getSubscriptionTier = (customerInfo: any) => {
  const activeEntitlement = customerInfo.entitlements.active
  return (
    (activeEntitlement && Object.keys(activeEntitlement)[0]) ||
    ('free' as SubscriptionTierKey)
  )
}

const getSubscriptionInfo = (customerInfo: any) => {
  const activeEntitlements = customerInfo.entitlements.active
  const activeEntitlement =
    (activeEntitlements && Object.values(activeEntitlements)[0]) || undefined
  activeEntitlement.managementURL = customerInfo.managementURL
  return activeEntitlement
}

const getNonSubscriptionTransactions = (customerInfo: any) => {
  if (Platform.OS === 'web') {
    let nonSubscriptionTransactions: any[] = []
    for (let key in customerInfo?.allPurchaseDatesByProduct) {
      if (key.includes('credit')) {
        nonSubscriptionTransactions.push({
          purchaseDate: customerInfo?.allPurchaseDatesByProduct[key],
          productIdentifier: key,
        })
      }
    }
    return nonSubscriptionTransactions
  }
  return customerInfo.nonSubscriptionTransactions
}

export const extractSubscriptionStatusFromCustomerInfo = (
  customerInfo:
    | (CustomerInfo & { non_subscriptions?: any })
    | (Subscriber & { nonSubscriptionTransactions?: NonSubscriptions }),
): {
  subscriptionTier: SubscriptionTierKey
  creditsPurchased: number
  subscriptionInfo?: PurchasesEntitlementInfo
} => {
  // TODO: fix this type
  // @ts-ignore
  const subscriptionTier: SubscriptionTierKey =
    getSubscriptionTier(customerInfo)
  const subscriptionInfo: PurchasesEntitlementInfo =
    getSubscriptionInfo(customerInfo)
  const nonSubscriptionTransactions =
    getNonSubscriptionTransactions(customerInfo)
  const creditsPurchased = nonSubscriptionTransactions
    .map((nst: any) => Number(nst.productIdentifier.split('_')[1]))
    .reduce((a: number, b: number) => a + b, 0)
  return { subscriptionTier, creditsPurchased, subscriptionInfo }
}
