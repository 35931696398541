import { getLocales } from 'react-native-localize'
import { SUPPORTED_LANGUAGES } from '../../i18n/config'
import {
  BODY_FONT,
  BODY_FONT_ARABIC,
  BODY_FONT_CYRILLIC,
  SANS_BOOK_FONT,
  SANS_BOOK_FONT_ARABIC,
  SANS_BOOK_FONT_CYRILLIC,
} from '../../constants/constants'
import { FastTag } from '../../../../api/_openapi'
import { ScriptType } from '../../../../api/frontend-types'

// Get the language code of the device
// (User may have multiple in settings, but we just want the first)
export const getDeviceLanguageCode = (): string => {
  const locales = getLocales()
  // Get language from subdomain
  const subdomainLang = (window?.location?.hostname || '').split('.')[0]
  if (isSupportedLanguage(subdomainLang)) {
    return subdomainLang
  } else if (!locales || locales.length === 0) {
    return 'en'
  } else {
    return locales[0].languageCode
  }
}

export function isSupportedLanguage(languageCode: string | undefined): boolean {
  if (!languageCode) {
    return false
  }
  return SUPPORTED_LANGUAGES.includes(languageCode)
}

export function isRtlLanguage(languageCode: string): boolean {
  return ['ar', 'fa', 'he'].includes(languageCode)
}

// Get a suffix for fonts based on the language
export function getFontSuffix(languageCode: string): string {
  switch (languageCode) {
    case 'fa':
      return 'Arabic'
    case 'ru':
      return 'Cyrillic'
    case 'uk':
      return 'Cyrillic'
    case 'el':
      return 'Cyrillic'
    default:
      return ''
  }
}

function getFontSuffixForScriptType(scriptType: ScriptType): string {
  switch (scriptType) {
    case 'arabic':
      return 'Arabic'
    case 'cyrillic':
      return 'Cyrillic'
    default:
      return ''
  }
}

export function getSansFont(
  languageCode: string,
  scriptType?: ScriptType,
): string {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    switch (scriptType) {
      case 'arabic':
        return SANS_BOOK_FONT_ARABIC
      case 'cyrillic':
        return SANS_BOOK_FONT_CYRILLIC
      default:
        return SANS_BOOK_FONT
    }
  }

  // Else, use the language code
  switch (languageCode) {
    case 'fa':
      return SANS_BOOK_FONT_ARABIC
    case 'ru':
      return SANS_BOOK_FONT_CYRILLIC
    case 'uk':
      return SANS_BOOK_FONT_CYRILLIC
    case 'el':
      return SANS_BOOK_FONT_CYRILLIC
    default:
      return SANS_BOOK_FONT
  }
}

// i.e. 'sans' or 'sansArabic'
export function getSansFontKey(
  languageCode: string,
  scriptType?: ScriptType,
): string {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    const suffix = getFontSuffixForScriptType(scriptType)
    return `sans${suffix}`
  }
  // Else, use the language code
  else {
    const suffix = getFontSuffix(languageCode)
    return `sans${suffix}`
  }
}

export const getHeadingFontKey = (
  languageCode: string,
  scriptType?: ScriptType,
): string => {
  // If scripttype (arabic, latin) is provided, use that
  if (scriptType) {
    const suffix = getFontSuffixForScriptType(scriptType)
    return `heading${suffix}`
  }
  // Else, use the language code
  else {
    const suffix = getFontSuffix(languageCode)
    return `heading${suffix}`
  }
}

export function getBodyFont(languageCode: string): string {
  console.log(`languageCode: ${languageCode}`)

  switch (languageCode) {
    case 'fa':
      return BODY_FONT_ARABIC
    case 'ru':
      return BODY_FONT_CYRILLIC
    case 'uk':
      return BODY_FONT_CYRILLIC
    case 'el':
      return BODY_FONT_CYRILLIC
    default:
      return BODY_FONT
  }
}

export const getScriptTypeForLanguage = (languageCode?: string): ScriptType => {
  switch (languageCode) {
    case 'fa':
      return 'arabic'
    case 'ru':
      return 'cyrillic'
    case 'uk':
      return 'cyrillic'
    case 'el':
      return 'cyrillic'
    default:
      return 'latin'
  }
}

export function getLanguageForTag(tag: FastTag, userLanguage: string): string {
  const tagType = tag.type

  switch (tagType) {
    case 'THEME':
      return userLanguage
    case 'SYMBOL':
      return userLanguage
    case 'DREAM_TYPE':
      return userLanguage
    default:
      return tag.languageCode || userLanguage
  }
}

// Take a language code and decide if it's a variety of French
export function isFrench(languageCode: string): boolean {
  return languageCode === 'fr' || languageCode.startsWith('fr-')
}

export function isEnglish(languageCode: string): boolean {
  // If the code starts with 'en', it's English
  return languageCode === 'en' || languageCode.startsWith('en-')
}

export function isGerman(languageCode: string): boolean {
  // If the code starts with 'de', it's German
  return languageCode === 'de' || languageCode.startsWith('de-')
}

export function isHungarian(languageCode: string): boolean {
  // If the code starts with 'hu', it's Hungarian
  return languageCode === 'hu' || languageCode.startsWith('hu-')
}

export function isJapanese(languageCode: string): boolean {
  // If the code starts with 'ja', it's Japanese
  return languageCode === 'ja' || languageCode.startsWith('ja-')
}

export function isPolish(languageCode: string): boolean {
  // If the code starts with 'pl', it's Polish
  return languageCode === 'pl' || languageCode.startsWith('pl-')
}
